@charset "UTF-8";
// -----------------------------------------------------------------------------
// Error
// -----------------------------------------------------------------------------

// utility
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/util/_icon-char'; // task
@import '../../../../../support/front/scss/util/_variable.scss';
@import '../../../../../support/front/scss/util/_function.scss';
@import '../../../../../support/front/scss/util/_mixin.scss';

// vendor style (npm dependencies)
// -----------------------------------------------------------------------------
@import 'sass-mq/_mq.scss';

// base
@import '../../../../../support/front/scss/base/_reset';
@import '../../../../../support/front/scss/base/_helper.scss';

// module
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/module/button';

// font
// -----------------------------------------------------------------------------
@each $filename, $props in $font-list-kor {
  @include font-face(nth($props, 1), $filename, nth($props, 2));
}

html,
body {
  min-height: 100%;
}

.error {
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  padding: 100px 0;
  margin: 0 rem(20);
  font-size: rem(16);
  line-height: 1.6;
  color: $gray9;
  min-height: 500px;

  .btn {
    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;

      &::before {
        transform: scale(1, 1);
        transform-origin: left center;
      }
    }
  }

  &__logo {
    width: 120px;
    margin-bottom: rem(30);
    line-height: 0;
  }

  &__h {
    font-family: $font-sub-kor;
    font-size: rem(36);
    line-height: 1.2;
  }

  &__desc {
  }

  &__func {
    margin-top: rem(25);
  }

  &__footer {
    position: absolute;
    bottom: 40px;
    left: 0;
  }

  &__cpy {
    color: $gray6;
    font-family: $font-sub-kor;
    font-size: rem(15);
  }

  @include mq($until: small) {
    &__func {
      .btn {
        display: block;
      }
    }
  }

  @include mq(small) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    text-align: center;

    &__box {
      max-width: 480px;
    }

    &__logo {
      margin-left: auto;
      margin-right: auto;
    }

    &__func {
      margin-top: rem(40);
    }

    &__footer {
      bottom: 8vh;
      right: 0;
      text-align: center;
    }
  }

  @include mq(medium) {
    font-size: rem(18);

    &__logo {
      width: 156px;
      margin-bottom: rem(45);
    }

    &__h {
      font-size: rem(68);
    }
  }
}
