html {
  background-color: #fff;
  font-size: 62.5%;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

body {
  min-width: 280px;
  margin: 0;
  font-size: 1.8em;
  font-family: "NotoSans", sans-serif;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  [lang='en'] body {
    font-family: "Roboto", sans-serif; }

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

ul,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0; }

ol,
ul {
  list-style: none; }

em,
address {
  font-style: normal; }

figure {
  margin: 0; }

hr {
  box-sizing: content-box;
  overflow: visible;
  height: 0; }

button {
  -webkit-appearance: none;
  box-sizing: content-box;
  padding: 0;
  border: 0;
  background: transparent;
  color: #101820;
  cursor: pointer; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show; }

td {
  vertical-align: middle; }

em {
  color: #0066CC; }

small {
  font-size: 80%; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  word-break: keep-all;
  word-wrap: break-word; }

p {
  word-break: keep-all;
  word-wrap: break-word; }

a {
  color: #101820;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

iframe {
  border: 0; }

img,
video {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: top;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

summary {
  display: list-item; }

::selection {
  background: #0071E3;
  color: #FFF;
  text-shadow: none; }

template,
.hidden,
[hidden] {
  display: none !important; }

.a11y {
  position: absolute !important;
  overflow: hidden;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px); }

@media (max-width: 44.99em) {
  .for-medium {
    display: none !important; } }

@media (max-width: 59.99em) {
  .for-large {
    display: none !important; } }

@media (min-width: 45em) {
  .for-until-medium {
    display: none !important; } }

@media (min-width: 60em) {
  .for-until-large {
    display: none !important; } }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

.btn {
  box-sizing: border-box;
  display: inline-block;
  overflow: visible;
  position: relative;
  padding: 14px 29px;
  border: 1px solid #101820;
  background-color: transparent;
  color: #101820;
  font-size: 17px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  vertical-align: middle;
  transition: color 0.28s cubic-bezier(0.25, 0.45, 0.5, 1);
  line-height: 1.2; }
  .btn + .btn {
    margin-left: 0.5em; }
  .btn .btn__text,
  .btn span {
    position: relative;
    z-index: 1;
    display: inline-block;
    pointer-events: none; }
  .btn::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    content: '';
    background-color: #0071E3;
    transition: transform 0.28s cubic-bezier(0.25, 0.45, 0.5, 1);
    transform: scale(0, 1);
    transform-origin: right center; }
  .btn::after {
    position: relative;
    transition: transform 0.28s cubic-bezier(0.25, 0.45, 0.5, 1); }
  .no-touchevents .btn:hover, .no-touchevents .btn:focus {
    text-decoration: none;
    color: #FFF; }
    .no-touchevents .btn:hover::before, .no-touchevents .btn:focus::before {
      transform: scale(1, 1);
      transform-origin: left center; }
  .btn[disabled] {
    opacity: 0.3;
    cursor: not-allowed; }
    .btn[disabled]:hover, .btn[disabled]:focus {
      color: inherit;
      border-color: inherit;
      background-color: inherit; }
      .btn[disabled]:hover::before, .btn[disabled]:focus::before {
        transform: scale(0, 1); }
  .btn--sm {
    padding: 8.5px 19px;
    font-size: 16px; }
  .btn--lg {
    padding: 17.5px 39px;
    font-size: 18px; }
  .btn--block {
    display: block;
    width: 100%; }
  .btn--em {
    background-color: #101820;
    color: #FFF; }
    .btn--em[disabled]:hover, .btn--em[disabled]:focus {
      color: #FFF;
      border-color: #101820;
      background-color: #101820; }
  .btn--ghost {
    border-color: #FFF;
    color: #FFF; }
    .btn--ghost[disabled]:hover, .btn--ghost[disabled]:focus {
      color: #FFF;
      border-color: #FFF; }
  .btn--invert {
    background-color: #FFF;
    border-color: #FFF; }
    .btn--invert[disabled]:hover, .btn--invert[disabled]:focus {
      border-color: #FFF;
      background-color: #FFF; }

.btn-more {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border: 1px solid #101820;
  transition: 0.25s cubic-bezier(0.25, 0.45, 0.5, 1); }
  .btn-more:before {
    content: '\ea13';
    display: inline-block;
    font-family: 'icon';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    font-size: 16px;
    transition: 0.3s cubic-bezier(0.25, 0.45, 0.5, 1);
    color: #101820; }
  .btn-more:hover, .btn-more:focus {
    background-color: #101820; }
    .btn-more:hover::before, .btn-more:focus::before {
      transform: rotate(90deg);
      color: #FFF; }

.btn-download:after {
  content: '\ea0c';
  display: inline-block;
  font-family: 'icon';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  margin: -0.1rem 0 0 0.4rem;
  vertical-align: middle;
  line-height: 1; }

.btn-add {
  padding-left: 4rem; }
  .btn-add:after {
    content: '\ea13';
    display: inline-block;
    font-family: 'icon';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    position: absolute;
    top: 10px;
    left: 18px; }

.btn-del {
  padding-left: 4rem; }
  .btn-del::after {
    content: '';
    position: absolute;
    top: 17px;
    left: 18px;
    width: 12px;
    height: 2px;
    background-color: #101820; }
  .no-touchevents .btn-del:hover::after, .no-touchevents .btn-del:focus::after {
    background-color: #FFF; }

.btn-refresh {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background-color: #FFF;
  border: 1px solid #D8D8D8;
  color: #D8D8D8;
  transition: border-color 0.4s cubic-bezier(0.25, 0.45, 0.5, 1), color 0.4s cubic-bezier(0.25, 0.45, 0.5, 1); }
  .btn-refresh:after {
    content: '\ea14';
    display: inline-block;
    font-family: 'icon';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none; }
  .btn-refresh:hover, .btn-refresh:focus {
    color: #101820;
    border-color: #101820; }

.btn-sound {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background-color: #FFF;
  border: 1px solid #D8D8D8;
  color: #D8D8D8;
  transition: border-color 0.4s cubic-bezier(0.25, 0.45, 0.5, 1), color 0.4s cubic-bezier(0.25, 0.45, 0.5, 1); }
  .btn-sound:after {
    content: '\ea1a';
    display: inline-block;
    font-family: 'icon';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none; }
  .btn-sound:hover, .btn-sound:focus {
    color: #101820;
    border-color: #101820; }

@font-face {
  font-family: "NotoSans";
  font-style: normal;
  font-weight: 700;
  src: url(../font/NotoSans-Bold.woff2) format("woff2"), url(../font/NotoSans-Bold.woff) format("woff"); }

@font-face {
  font-family: "NotoSans";
  font-style: normal;
  font-weight: 400;
  src: url(../font/NotoSans-Regular.woff2) format("woff2"), url(../font/NotoSans-Regular.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(../font/Roboto-kor-Bold.woff2) format("woff2"), url(../font/Roboto-kor-Bold.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url(../font/Roboto-kor-Medium.woff2) format("woff2"), url(../font/Roboto-kor-Medium.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(../font/Roboto-kor-Regular.woff2) format("woff2"), url(../font/Roboto-kor-Regular.woff) format("woff"); }

html,
body {
  min-height: 100%; }

.error {
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  padding: 100px 0;
  margin: 0 2rem;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #101820;
  min-height: 500px; }
  .error .btn:hover, .error .btn:focus {
    text-decoration: none;
    color: #FFF; }
    .error .btn:hover::before, .error .btn:focus::before {
      transform: scale(1, 1);
      transform-origin: left center; }
  .error__logo {
    width: 120px;
    margin-bottom: 3rem;
    line-height: 0; }
  .error__h {
    font-family: "Roboto", "NotoSans", sans-serif;
    font-size: 3.6rem;
    line-height: 1.2; }
  .error__func {
    margin-top: 2.5rem; }
  .error__footer {
    position: absolute;
    bottom: 40px;
    left: 0; }
  .error__cpy {
    color: #54565B;
    font-family: "Roboto", "NotoSans", sans-serif;
    font-size: 1.5rem; }
  @media (max-width: 34.99em) {
    .error__func .btn {
      display: block; } }
  @media (min-width: 35em) {
    .error {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      text-align: center; }
      .error__box {
        max-width: 480px; }
      .error__logo {
        margin-left: auto;
        margin-right: auto; }
      .error__func {
        margin-top: 4rem; }
      .error__footer {
        bottom: 8vh;
        right: 0;
        text-align: center; } }
  @media (min-width: 45em) {
    .error {
      font-size: 1.8rem; }
      .error__logo {
        width: 156px;
        margin-bottom: 4.5rem; }
      .error__h {
        font-size: 6.8rem; } }
